<template>
    <div style="margin-bottom: 7rem !important">
        <h1 v-if="!id">{{ form.id ? '�ndra' : 'Skapa' }} dashboards</h1>
        <hr />
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-name"
                          label="Namn"
                          label-for="input-name">
                <b-form-input id="input-name"
                              v-model="form.name"
                              type="text"
                              placeholder="Ange namn"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-name-help-block" />
                <b-form-text id="input-name-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Name')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sortOrder" label="Sorteringsordning" label-for="input-sortOrder">
                <b-form-input id="input-sortOrder"
                              v-model="form.sortOrder"
                              type="number"
                              min="1"
                              max="2,147,483,647"
                              placeholder="Ange sorteringsordning"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-sortOrder-help-block"
                              required />
                <b-form-text id="input-sortOrder-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SortOrder')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-roles" label="Roller" label-for="input-roles">
                <b-form-checkbox-group id="input-roles"
                                       v-model="form.roles"
                                       :options="rolesOptions"
                                       :disabled="isSubmitting"
                                       aria-describedby="input-roles-help-block"
                                       value-field="name"
                                       text-field="name"
                                        />
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Roles')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-form-group>

            <b-row class="m-0">
                <b-col md="4" sm="12" class="pl-0">
                    <b-form-group id="input-group-isActive"
                                  label="Aktiv"
                                  label-for="input-isActive">
                        <b-form-checkbox id="input-isActive"
                                         v-model="form.isActive"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isActive-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsActive')" :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <b-alert :show="successMessage.length > 0"
                     variant="success"
                     class="mb-0 mt-4">
                <h6 class="mb-0 text-center">
                    <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                </h6>
            </b-alert>

            <b-button v-b-tooltip.hover
                      variant="primary"
                      class="float-right mt-4"
                      :class="{ 'btn-block': id }"
                      :disabled="isSubmitting"
                      type="submit"
                      :title="isSubmitting ? 'Sparar...' : ''">
                <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
            </b-button>
        </b-form>
    </div>
</template>

<script>
    import { get, post } from '@/helpers/api';

    export default {
        name: 'SaveDashBoard',
        components: {

        },
        props: {
            id: Number
        },
        data() {
            return {
                form: {
                    id: null,
                    name: '',
                    sortOrder: 999,
                    isActive: false,
                    roles: []
                },
                isSubmitting: false,
                rolesOptions: [
                    { name: 'SuperAdmin' },
                    { name: 'Administrator' },
                    { name: 'CustomerAdmin' }
                ],
                successMessage: '',
                validationErrors: null,
            };
        },
        computed: {
            isEditMode() {
                return this.form.id > 0;
            }
        },

        async mounted() {
            // Get widget if necessary.
            this.form.id = typeof this.id !== 'undefined' ? this.id : 0;

            if (this.isEditMode) {
                await get('Dashboard', `Get?id=${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;
                        this.form.roles = x.data.roles.split(',');
                    })
                    .catch((x) => {
                        alert(x.data);
                    });
            }
            // Get roles.
            await get('Role', 'GetAll').then(x => this.rolesOptions = x.data);
        },
        methods: {
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                await post('Dashboard', 'Save', { ...this.form, roles: this.form.roles.toString() })
                    .then((x) => {
                        if (x.data) {
                            // If this is the first save (form.id is null), set form.id to entityId
                            if (!this.form.id && x.data.entityId) {
                                this.form.id = x.data.entityId;
                            }
                            this.successMessage = `${this.form.id ? 'Uppdaterade' : 'Skapade'} ${this.form.name}`;
                        }
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });

                this.isSubmitting = false;

                // On created .
                if (!this.validationErrors && this.successMessage) {
                    this.onCreated();
                }
            },

            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    name: '',
                    sordOrder: null,
                    isActive: false,
                    roles: ''
                };
                this.validationErrors = null;
            },
            async confirm() {
                this.$bvModal
                    .msgBoxConfirm('F�r�ndringar har skett', {
                        title: '�r du s�ker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        return confirmed;
                    });
            },
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
